<template>
  <v-container fluid>
    <router-link id="goBack" flat :to="{ name: 'admin', params: { tab: 'Party Attributes' } }">
      <v-btn text small color="primary">
        <v-icon color="primary">mdi-chevron-left</v-icon>Go Back
      </v-btn>
    </router-link>
    <br />
    <br />

    <v-card>
      <v-card-title>Attribute Edit</v-card-title>
      <v-card-subtitle>Attributes are party specific variables</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-row align="start" dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                color="primary"
                dense
                outlined
                label="Attribute Name"
                v-model="attribute.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Attribute ID"
                v-model="attributeID"
                :disabled="disableAttributeId"
                hint="Must be in all CAPS, no spaces and special characters '_'"
                :persistent-hint="true"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                dense
                outlined
                label="Data Type"
                item-text="name"
                item-value="id"
                :items="data_types"
                v-model="attribute.data_type"
                color="primary"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                dense
                outlined
                label="Party Type"
                item-text="name"
                item-value="id"
                :items="party_types"
                v-model="attribute.party_type"
                color="primary"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
               <v-text-field
                dense
                outlined
                label="Validation String"
                v-model="attribute.validation_string"
                hint="Must be a valid regular expression (regex) statement"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="attribute.data_type && attribute.data_type.constant === 'KEY_VALUE'">
              <v-combobox
                dense
                outlined
                label="Valid Value Key"
                item-text="name"
                item-value="id"
                :items="valid_key_types"
                v-model="attribute.valid_value_key"
                color="primary"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="attribute.data_type && (attribute.data_type.constant === 'STRING' || attribute.data_type.constant === 'TEXT')">
               <v-text-field
                dense
                outlined
                label="Max Length"
                v-model="attribute.max_length"
                required
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
               <v-text-field
                dense
                outlined
                label="Info"
                v-model="attribute.info"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                type="number"
                label="Display Order"
                v-model="attribute.display_order"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="attribute.data_type">
              <v-combobox
                v-if="attribute.data_type.constant === 'KEY_VALUE'"
                dense
                outlined
                label="Default Value"
                item-text="name"
                item-value="id"
                :items="keyValues"
                v-model="attribute.default_key_value"
                clearable
                color="primary"
              ></v-combobox>
              <v-select
                v-else-if="attribute.data_type.constant === 'BOOLEAN'"
                :menu-props="{ offsetY: true }"
                dense
                outlined
                label="Default Value"
                :items="['True', 'False']"
                v-model="attribute.default_value"
                clearable
                color="primary"
              ></v-select>
               <v-text-field
                v-else
                dense
                outlined
                label="Default Value"
                v-model="attribute.default_value"
                color="primary"
              ></v-text-field>
            </v-col>
          </v-row>

            <v-checkbox
              color="primary"
              v-model="attribute.is_time_sensitive"
              class="mt-0 pt-0"
              label="Is it time sensitive?"
              absolute
              right
            ></v-checkbox>
            <v-checkbox
              color="primary"
              v-model="attribute.is_required"
              class="mt-0 pt-0"
              label="Is it required?"
              absolute
              right
            ></v-checkbox>
            <v-checkbox
              color="primary"
              v-model="attribute.is_searchable"
              class="mt-0 pt-0"
              label="Is it searchable?"
              absolute
              right
            ></v-checkbox>
            <v-checkbox
              color="primary"
              v-model="attribute.allow_multiple"
              class="mt-0 pt-0"
              label="Allow Multiple Records"
              absolute
              right
            ></v-checkbox>

          <v-row>
            <v-col>
              <v-btn color="success" class="mr-4" id="saveBtn" @click="saveAttribute">Save</v-btn>
              <div class="information-block pt-10 mb-0">
                Created: {{attribute.created_by}} - {{moment(attribute.created_on).format($config.timestamp)}}
                <br/>
                Updated: {{attribute.updated_by}} - {{moment(attribute.updated_on).format($config.timestamp)}}
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// API
import attributeApi from '@/axios/party-attribute-endpoint'
import KVApi from '@/axios/key-values-endpoint'

// third-party
import { cloneDeep } from 'lodash'

// mixins
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'partyAttributeEdit',
  data () {
    return {
      attribute: {
        name: '',
        id: '',
        info: null,
        is_time_sensitive: false,
        is_required: false,
        is_searchable: false,
        allow_multiple: false,
        max_length: 0,
        validation_string: null,
        data_type: '',
        party_type: '',
        valid_value_key: {
          name: '',
          id: ''
        },
        display_order: 0,
        default_value: null,
        default_key_value: {
          name: '',
          id: ''
        },
        created_by: '',
        updated_by: ''
      },
      crud: 'create',
      data_types: [],
      party_types: [],
      valid_key_types: [],
      keyValues: []
    }
  },
  computed: {
    attributeID: {
      get () {
        return this.attribute.id
      },
      set (value) {
        this.attribute.id = value.toUpperCase().replace(/\s/g, '_')
      }
    },
    attributeName: {
      get () {
        return this.attribute.name !== ''
          ? this.attribute.name
          : 'New Attribute'
      },
      set (newValue) {
        return this.attribute.name
      }
    },
    attributeParty: {
      get () {
        return this.attribute.party_type.name !== ''
          ? this.attribute.party_type.name
          : 'Attribute For Party'
      },
      set (newValue) {
        return this.attribute.party_type.name
      }
    },
    disableAttributeId () {
      return this.crud === 'update'
    }
  },
  watch: {
    'attribute.name': {
      deep: true,
      handler (newValue, oldValue) {
        if (!this.disableAttributeId) {
          if (
            oldValue.toLowerCase() ===
            this.attribute.id.toLowerCase().replace(/_/g, ' ')
          ) {
            this.attribute.id = newValue.toUpperCase().replace(/\s/g, '_')
          }
        }
      }
    }
  },
  mixins: [displayAlert],
  created () {
    this.initDataTypes()
    this.initPartyTypes()
    this.initValidKeyTypes()
    if (this.$route.query.hasOwnProperty('id')) {
      this.crud = 'update'
      this.initAttribute()
    }
  },
  methods: {
    async initAttribute () {
      await attributeApi.attribute.get(this.$route.query.id).then(async response => {
        const initalValue = { name: '', id: '' }
        this.attribute = response.data
        if (!this.attribute.valid_value_key || (Object.keys(this.attribute.valid_value_key).length === 0 && this.attribute.valid_value_key.constructor === Object)) {
          this.attribute.valid_value_key = initalValue
        }
        await this.getKeyValues()
      }).catch(err => {
        this.handleError(err)
      })
    },
    async initDataTypes () {
      await KVApi.keyValues.getList('PARTY_ATTR_DATA_TYPE', 50, 0).then(response => {
        this.data_types = response.data
      }).catch(err => {
        this.handleError(err)
      })
    },
    async initPartyTypes () {
      await KVApi.keyValues.getList('PARTY_TYPE', 50, 0).then(response => {
        this.party_types = response.data
      }).catch(err => {
        this.handleError(err)
      })
    },
    async initValidKeyTypes () {
      await KVApi.keys.getList(50, 0).then(response => {
        this.valid_key_types = response.data
      }).catch(err => {
        this.handleError(err)
      })
    },
    async getKeyValues () {
      if (!this.attribute.valid_value_key.id) return
      await KVApi.keyValues.getList(this.attribute.valid_value_key.id, 100, 0)
        .then(response => {
          this.keyValues = response.data
          if (this.attribute.default_key_value && Object.entries(this.attribute.default_key_value).length === 0) {
            this.attribute.default_key_value = null
          }
        }).catch(err => {
          this.handleError(err)
        })
    },
    saveAttribute () {
      this.alert = false
      if (!(this.attribute.data_type.constant === 'STRING' || this.attribute.data_type.constant === 'TEXT')) {
        this.attribute.max_length = 0
      }

      const saveData = cloneDeep(this.attribute)
      saveData.data_type_id = cloneDeep(this.attribute.data_type.id)
      saveData.party_type_id = cloneDeep(this.attribute.party_type.id)
      if (this.attribute.valid_value_key.id !== '') {
        saveData.valid_value_key_id = cloneDeep(
          this.attribute.valid_value_key.id
        )
      }
      if (saveData.display_order === '') {
        saveData.display_order = 0
      }

      if (!this.attribute.default_key_value) {
        saveData.default_key_value_id = null
      } else if (this.attribute.default_key_value.id !== '') {
        saveData.default_key_value_id = cloneDeep(
          this.attribute.default_key_value.id
        )
      }

      if (this.attribute.default_value === '') {
        saveData.default_value = null
      }

      delete saveData.data_type
      delete saveData.party_type
      delete saveData.valid_value_key
      delete saveData.default_key_value
      delete saveData.created_on
      delete saveData.updated_on

      if (this.valid(['name', 'id', 'data_type', 'party_type'])) {
        if (this.crud === 'create') {
          this.saveNew(saveData)
        } else {
          this.updateAttribute(saveData)
        }
      } else {
        this.emitAlert(true, 'warning', `Please check the following fields: ${this.errors.join(', ')}`)
      }
    },
    async saveNew (saveData) {
      delete saveData.updated_by
      await attributeApi.attribute.post(saveData).then(() => {
        this.emitAlert(true, 'success', 'Your Attribute has been successfully updated!')
      }).catch(err => {
        this.handleError(err)
      })
    },
    async updateAttribute (saveData) {
      saveData.updated_by = this.$store.getters.email
      saveData.attr_group_id = saveData.attr_group.id
      const saveId = saveData.id
      delete saveData.created_by
      delete saveData.id

      await attributeApi.attribute.put(saveId, saveData).then(() => {
        this.emitAlert(true, 'success', 'Your Attribute has been successfully updated!')
      }).catch(err => {
        this.handleError(err)
      })
    },
    valid (fields) {
      this.errors.length = 0
      let returnValue = true

      fields.forEach(field => {
        if (this.attribute[field] === '') {
          this.errors.push(field)
          returnValue = false
        }
      })
      return returnValue
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.errorItem {
  padding-left: 1.5rem;
}
h5 {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
}
.createNew {
  font-style: italic;
  font-size: 0.8rem;
  text-align: right;
  margin-bottom: 0.5rem;
}
.information-block {
  font-style: italic;
}
</style>
